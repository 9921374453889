export const environment = {
  production: true,
  //serverUrl: 'http://localhost:8082/',
  // serverUrl: 'https://back.quartier.com.co/phyc-api/',
  serverUrl: "https://back.sandbox.quartier.com.co/phyc-api/",
};
export const environmentFire = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCjI7pNwwob8OVl3bloysqju1wKgIgm_3A",
    authDomain: "fir-car-3c1a8.firebaseapp.com",
    databaseURL: "https://fir-car-3c1a8.firebaseio.com",
    projectId: "fir-car-3c1a8",
    storageBucket: "fir-car-3c1a8.appspot.com",
    messagingSenderId: "968451523988",
    appId: "1:968451523988:web:b1c1aa8d801a0c1d9bfb81",
    measurementId: "G-CK0TDF2RXT",
  },
};
