import { SocialAreaTypeModel } from "./social-area-type";
import { HousingUnitModel } from "./housing-unit-model";
export class SocialAreaModel {
  id: string;
  name: string;
  capacityArea: string;
  area: number;
  rentalPrice: string;
  cleaningPrice: string;
  location: string;
  housingUnitId: HousingUnitModel;
  socialAreaTypeId: SocialAreaTypeModel;
  closingHour: string;
  openingHour: string;
  allowedHours: string;
  paymentType: string;
  disabledDays: any;
  exclusive: Boolean;

  public constructor() {
    this.id = "";
    this.name = "";
    this.capacityArea = "";
    this.rentalPrice = "";
    this.cleaningPrice = "";
    this.location = "";
    this.openingHour = "";
    this.closingHour = "";
    this.housingUnitId = new HousingUnitModel();
    this.socialAreaTypeId = new SocialAreaTypeModel();
    this.allowedHours = "";
    this.paymentType = undefined;
    this.area = 0;
    this.disabledDays = [];
    this.exclusive = false;
  }
}
